.weather {
    background: linear-gradient(to right, #fcf8f8, #fcf8f8);
    border-radius: 10px;
    color: rgb(0, 0, 0);
    padding: 20px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    text-align: center;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.city {
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
}

.description {
    font-size: 1.2em;
    text-align: center;
    margin: 5px 0;
}

.bottom {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    text-align: left;
}

.temperature {
    font-size: 2em;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.details-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.details-row span {
    font-size: 0.9em;
}

.weather-icon {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
